import React, { useState, useEffect } from "react";
import "./App.css";
import { forwardRef } from "react";
import Grid from "@material-ui/core/Grid";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { TablePagination } from "@material-ui/core";

import { Delete, Build } from "@material-ui/icons";
import DatasetIcon from "@mui/icons-material/Dataset";
import { MenuItem, Select } from "@mui/material";

//https://github.com/mbrn/material-table/pull/2937
function PatchedPagination(props) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const jenkins_url = "http://jenkins.devtools.ravenpack.com/job";
const api = axios.create({
  baseURL: "https://scalable-validation-api.devtools.ravenpack.com",
  // baseURL: "https://sv-manager-api.devtools.ravenpack.com",
  // baseURL: 'http://localhost:8080'
});

//todo: embed another table on row detail https://github.com/mbrn/material-table/issues/2185

// function validateEmail(email) {
//     const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
//     return re.test(String(email).toLowerCase());
// }

function LoadingModal(props) {
  return (
    <div
      style={{ display: props.isLoading ? "flex" : "none" }}
      className="modal"
    >
      <div className="modal-content">
        <div className="loader"></div>
        <div className="modal-text">Loading...</div>
      </div>
    </div>
  );
}

function MainTable({ handleErrorsChange }, props) {
  let DropDown = ({ value, onChange }) => (
    <Select onChange={onChange} value={value || "Select"}>
      <MenuItem value="template">template</MenuItem>
      <MenuItem value="flag">flag</MenuItem>
    </Select>
  );

  var columns = [
    { title: "Batch Name", field: "NAME" },
    { title: "Branch", field: "BRANCH" },
    {
      title: "Validation Type",
      field: "TYPE",
      editComponent: (props) => (
        <DropDown
          value={props.value}
          onChange={(e) => {
            // console.log(e.target.value);
            props.onChange(e.target.value);
          }}
        />
      ),
    },
    {
      title: "Athena source table",
      field: "ATHENA_PARQUET_TABLE",
      hidden: true,
    },
    { title: "Oracle results table", field: "ORACLE_RESULTS_TABLE" },
    { title: "Oracle reference table", field: "ORACLE_REFERENCE_TABLE" },
    { title: "Generation", field: "GENERATION" },
    { title: "Notes", field: "NOTES" },
    { title: "Management Console", field: "MC_URL" },
    { title: "MC Also In column Name", field: "ALSO_IN_NAME" },
  ];

  const [data, setData] = useState([]); //table data
  // const [selectedRow, setSelectedRow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation9
    let errorList = validate_fields(newData);
    errorList.push(...validate_immutable_columns(oldData, newData));
    handleErrorsChange(errorList);
    if (errorList.length < 1) {
      // console.log(newData)
      api
        .patch("/batch", newData)
        .then((res) => {
          // const dataUpdate = [...data];
          // const index = oldData.tableData.id;
          // dataUpdate[index] = newData;
          // setData([...dataUpdate]);
          resolve();
          handleErrorsChange([]);
        })
        .then((res) => {
          get_batches();
        })
        .catch((error) => {
          handleErrorsChange(["Update failed! Server error"]);
          resolve();
        });
    } else {
      handleErrorsChange(errorList);
      resolve();
    }
  };

  const handleRowAdd = (newData, resolve) => {
    //validation
    let errorList = validate_fields(newData);
    // console.log(errorList)
    handleErrorsChange(errorList);
    if (errorList.length < 1) {
      //no error
      api
        .post("/batches", newData)
        .then((res) => {
          // let dataToAdd = [...data];
          // dataToAdd.push(newData);
          // setData(dataToAdd);
          resolve();
          handleErrorsChange([]);
        })
        .then((res) => {
          get_batches();
        })
        .catch((error) => {
          handleErrorsChange([
            "Cannot add row. Error code: " + error,
            "Error message: " + error.response.data.detail,
          ]);
          resolve();
        });
    } else {
      handleErrorsChange(errorList);
      resolve();
    }
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const handleRowDelete = (oldData, resolve) => {
    api
      .delete("/batches", {
        params: {
          name: oldData.NAME,
          type: oldData.TYPE,
        },
      })
      //TODO: handle deletion by name+type
      .then((res) => {
        // const dataDelete = [...data];
        // const index = oldData.tableData.NAME;
        // dataDelete.splice(index, 1);
        // setData([...dataDelete]);
        resolve();
      })
      .then((res) => {
        get_batches();
      })
      .catch((error) => {
        handleErrorsChange(["Delete failed! Server error!"]);
        resolve();
      });
  };

  function validate_fields(newData) {
    // console.log(newData)
    let errorList = [];
    let empty_values = ["", undefined];
    if (empty_values.includes(newData.NAME)) {
      errorList.push("The name of the validation can not be empty!");
    }
    if (empty_values.includes(newData.BRANCH)) {
      errorList.push("The branch of the validation can not be empty!");
    }
    if (empty_values.includes(newData.TYPE)) {
      errorList.push("The type of the validation can not be empty!!");
    }
    if (empty_values.includes(newData.ORACLE_RESULTS_TABLE)) {
      errorList.push("The oracle results table can not be empty!!");
    }
    if (empty_values.includes(newData.ORACLE_REFERENCE_TABLE)) {
      errorList.push("The oracle reference table can not be empty!!");
    }
    if (empty_values.includes(newData.GENERATION)) {
      errorList.push("The generation can not be empty!!");
    }
    if (empty_values.includes(newData.MC_URL)) {
      errorList.push("The MC_URL can not be empty!!");
    }
    if (empty_values.includes(newData.NOTES)) {
      errorList.push("The notes can not be empty!!");
    }
    if (empty_values.includes(newData.ALSO_IN_NAME)) {
      errorList.push("The also_in column name can not be empty!!");
    }
    return errorList;
  }

  function validate_immutable_columns(oldData, newData) {
    let errorList = [];
    // console.log(newData)
    if (oldData.NAME !== newData.NAME) {
      errorList.push("You are not allowed to change the batch name!");
    }
    if (oldData.TYPE !== newData.TYPE) {
      errorList.push("You are not allowed to change the batch type!");
    }
    return errorList;
  }

  useEffect(() => {
    // console.log('rendering')
    get_batches();
  }, []);

  function get_batches() {
    setIsLoading(true);
    api
      .get("/batches", { timeout: 5000 })
      .then((res) => {
        // console.log(res.data)
        setData(res.data);
        setIsLoading(false);
        // return res.data
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          handleErrorsChange([
            "Can't connect to backend! Are you connected via vpn/office network?",
          ]);
        }
        console.log("Error:", error.code, error.message);
        // return error.code
      });
  }

  return (
    <>
      <LoadingModal isLoading={isLoading}></LoadingModal>
      <MaterialTable
        components={{
          Pagination: PatchedPagination,
        }}
        title="Scalable Validation Batches"
        columns={columns}
        data={data}
        options={{
          addRowPosition: "first",
          headerStyle: { backgroundColor: "#FFF", fontWeight: "bold" },
          // rowStyle: rowData => ({backgroundColor: (selectedRow.includes(rowData.tableData.id)) ? '#cce6ff' : '#FFF'})
        }}
        detailPanel={[
          {
            tooltip: "Show Validation Runs",
            render: (rowData) => {
              return (
                <Subtable batchName={rowData.NAME} batchType={rowData.TYPE} />
              );
            },
          },
        ]}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel();
        }}
        icons={tableIcons}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);
            }),
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve);
            }),
          // onRowDelete: (oldData) =>
          //     new Promise((resolve) => {
          //         handleRowDelete(oldData, resolve)
          //     }),
        }}
        actions={[
          {
            icon: () => <DatasetIcon />,
            tooltip: "Generate Validation Data",
            onClick: (event, rowData) => {
              if (rowData.TYPE === "template") {
                window.alert(
                  "Template validation data is not job specific! We generate it automatically every day at 5 pm.",
                );
              } else if (rowData.TYPE === "flag") {
                openInNewTab(
                  `${jenkins_url}/Scalable-Validation/job/Validation/job/get-g5-scalable-validation-data/parambuild/?FLAGS_BATCH=${rowData.NAME}&STAGE_TEMPLATES=false&STAGE_FLAGS=true`,
                );
              }
            },
          },
          {
            icon: () => <Build />,
            tooltip: "Open build in Jenkins",
            onClick: (event, rowData) => {
              let run_flags = "false";
              let selected_job =
                "Scalable-Validation/job/Validation/job/template-validation-generic";
              let source_bucket = "template_data_dev";
              let delete_stack = "true";
              if (
                rowData.TYPE === "template" &&
                window.confirm(
                  "Do you also want to run flags validation? If yes, make sure the batch definition exists with the same name and you have generated validation data!",
                )
              ) {
                run_flags = "true";
                delete_stack = "false";
              } else if (rowData.TYPE === "flag") {
                window.alert(
                  "If you need to reuse POS signatures other than the latest from gen5_daily_master, copy that to s3://com.ravenpack.scalable-validation/" +
                    rowData.NAME +
                    "/pos-signatures/",
                );
                selected_job =
                  "Scalable-Validation/job/Validation/job/flag-validation-generic";
                source_bucket = "flags_data_dev";
              }
              openInNewTab(
                `${jenkins_url}/${selected_job}/parambuild/?S3_IMAGE_PATH=PUT_YOUR_IMAGE_PATH_HERE&VALIDATION_BATCH=${rowData.NAME}&SOURCE_BUCKET=${source_bucket}&VALIDATION_WORKER_CAPACITY=40&STAGE_TRIGGER_FLAGS=${run_flags}&STAGE_STACK_DELETE=${delete_stack}`,
              );
            },
          },
          (rowData) => ({
            icon: () => <Delete />,
            tooltip: "Delete Validation batch",
            onClick: (event, rowData) =>
              new Promise((resolve) => {
                if (
                  window.confirm(
                    "Do you really want to delete the batch of " +
                      rowData.NAME +
                      " with type " +
                      rowData.TYPE +
                      " and respective runs data?",
                  )
                ) {
                  handleRowDelete(rowData, resolve);
                }
              }),
            disabled: ["gen5_daily_master", "gen5_daily_dr152"].includes(
              rowData.NAME,
            ),
          }),
        ]}
      />
    </>
  );
}

function Subtable(props) {
  const runColumns = [
    { title: "Batch Name", field: "NAME" },
    { title: "Validation Type", field: "TYPE" },
    { title: "Actual State", field: "STATE" },
    { title: "Start of the Validation", field: "START_UTC" },
    { title: "End of the Validation", field: "END_UTC" },
  ];
  const [runData, setRunData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function get_run() {
    setIsLoading(true);
    const params = new URLSearchParams();
    params.append("name", props.batchName);
    params.append("type", props.batchType);
    api
      .get("/run", {
        params,
      })
      .then((res) => {
        setRunData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error");
      });
  }

  useEffect(() => {
    get_run();
  }, []);

  // https://github.com/mbrn/material-table/issues/2451#issuecomment-857621598 this is not required anymore but I
  // leave it here since it was very annoying
  // without this, opening the subtable N times, crashes the browser....
  // const columns = props.runColumns.map((column) => {
  //     return {...column};
  // });

  return (
    <>
      {" "}
      <LoadingModal isLoading={isLoading}></LoadingModal>
      <div style={{ border: "1px solid blue", margin: "10px" }}>
        <MaterialTable
          columns={runColumns}
          data={runData}
          options={{
            headerStyle: {
              backgroundColor: "#FFF",
              fontWeight: "bold",
              zIndex: 1,
            },
            paging: false,
            toolbar: false,
            padding: "dense",
            actionsColumnIndex: -1,
            sorting: false,
          }}
        />
      </div>
    </>
  );
}

function App() {
  let DropDown = ({ value, onChange }) => (
    <Select onChange={onChange} value={value || "Select"}>
      <MenuItem value="template">template</MenuItem>
      <MenuItem value="flag">flag</MenuItem>
    </Select>
  );

  var columns = [
    { title: "Batch Name", field: "NAME" },
    { title: "Branch", field: "BRANCH" },
    {
      title: "Validation Type",
      field: "TYPE",
      editComponent: (props) => (
        <DropDown
          value={props.value}
          onChange={(e) => {
            // console.log(e.target.value);
            props.onChange(e.target.value);
          }}
        />
      ),
    },
    {
      title: "Athena source table",
      field: "ATHENA_PARQUET_TABLE",
      hidden: true,
    },
    { title: "Oracle results table", field: "ORACLE_RESULTS_TABLE" },
    { title: "Oracle reference table", field: "ORACLE_REFERENCE_TABLE" },
    { title: "Generation", field: "GENERATION" },
    { title: "Notes", field: "NOTES" },
    { title: "Management Console", field: "MC_URL" },
    { title: "MC Also In column Name", field: "ALSO_IN_NAME" },
  ];

  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  function handleSetErrors(newErrors) {
    setErrorMessages(newErrors);
    if (newErrors.length > 0) {
      setIserror(true);
    } else {
      setIserror(false);
    }
  }
  return (
    <div className="App">
      <Grid container spacing={1}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <div>
            {iserror && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
                <button onClick={() => setIserror(false)}>Clear Errors</button>
              </Alert>
            )}
          </div>
          <MainTable handleErrorsChange={handleSetErrors} />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  );
}

export default App;
